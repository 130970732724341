<template>
    <div class="input" :class="inputContainerClass">
        <label
            v-if="label"
            :for="`${_uid}-input`"
            class="input__label"
            v-text="label"/>
        <input
            :id="`${_uid}-input`"
            :name="label"
            v-model="val"
            :autocomplete="autocomplete"
            :type="type"
            class="input__field"/>
        <p class="input__message"
           v-if="message"
           v-text="message"/>
    </div>
</template>

<script>
    export default {
        name    : 'InputField',
        data    : () => {
            return {
                val: null,
            };
        },
        props   : {
            autocomplete: {
                required: false,
                default : null,
            },
            value       : {
                required: true,
            },
            type        : {
                required: false,
                type    : String,
                default : 'text',
            },
            label       : {
                default: null,
                type   : String,
            },
            message     : {
                required: false,
                type    : String,
                default : null,
            },
            isValid     : {
                default: null,
            },
        },
        computed: {
            inputContainerClass: function () {
                return this.isValid
                    ? 'input--success'
                    : 'input--invalid';
            },
            inputHasNoValidStatus() {
                return this.isValid === null;
            },
        },
        watch   : {
            val(value) {
                this.$emit('input', value);
            },
            value: {
                immediate: true,
                handler(value) {
                    this.val = value;
                },
            },
        },
    };
</script>
