<template>
    <div class="input" :class="inputContainerClass">
        <div class="input__checkbox">
            <input
                :id="`${_uid}-input`"
                :name="label"
                class="input__checkbox-item"
                type="checkbox"
                :autocomplete="autocomplete"
                v-model="checked">
            <label
                :for="`${_uid}-input`"
                class="input__label">
                {{ label }}
            </label>
            <p class="input__message"
               v-if="message"
               v-text="message"/>
        </div>
    </div>
</template>

<script>
    export default {
        name    : 'Checkbox',
        data    : () => {
            return {
                checked: false,
            };
        },
        props   : {
            autocomplete: {
                required: false,
                default : null,
            },
            value       : {
                required: true,
            },
            label       : {
                default: null,
                type   : String,
            },
            message     : {
                required: false,
                type    : String,
                default : null,
            },
            isValid     : {
                default: null,
            },
        },
        computed: {
            inputContainerClass: function () {
                return this.isValid
                    ? 'input--success'
                    : 'input--invalid';
            },
        },
        watch   : {
            checked: function (value) {
                this.$emit('input', value);
            },
            value  : {
                immediate: true,
                handler(value) {
                    this.checked = value;
                },
            },
        },
    };
</script>
