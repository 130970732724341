<template>
    <div>
        <div class="mx-auto container p-4 pt-12 pb-16">
            <loading-capsule
                :loaded="loaded"
                spinner-margin-top="10rem">
                <form id="accountEditForm" class="w-full" @submit.prevent="handleUpdatingDetails">
                    <div class="text-center">
                        <h1>{{ $t('ACCOUNT.TITLE') }}</h1>
                    </div>

                    <!--Primary details-->
                    <div>
                        <h3 class="text-center sm:text-left pl-3 pb-2 pt-6">
                            {{ $t('ACCOUNT.PRIMARY_DETAILS_TITLE') }}
                        </h3>
                        <div class="md:flex md:flex-wrap">
                            <!--Name-->
                            <input-field
                                class="w-full md:w-1/2"
                                v-model.trim="account.name"
                                autocomplete="given-name"
                                :is-valid="!errorHandler.has('name')"
                                :message="errorHandler.get('name')"
                                :label="$t('CORE.FIRST_NAME')"/>
                            <!--Email-->
                            <input-field
                                class="w-full md:w-1/2"
                                v-model.trim.lowercase="account.email"
                                type="email"
                                autocomplete="email"
                                :is-valid="!errorHandler.has('email')"
                                :message="errorHandler.get('email')"
                                :label="$t('CORE.EMAIL')"/>
                            <!--Password-->
                            <input-field
                                class="w-full md:w-1/2"
                                v-model="account.password"
                                type="password"
                                :is-valid="!errorHandler.has('password')"
                                :message="errorHandler.get('password')"
                                :label="$t('CORE.PASSWORD')"/>
                            <!--OptInMarketing-->
                            <checkbox
                                class="w-full"
                                v-model="account.opt_in"
                                :is-valid="!errorHandler.has('opt_in')"
                                :message="errorHandler.get('opt_in')"
                                :label="$t('CORE.OPT_IN_MARKETING')"/>
                        </div>
                    </div>

                    <!--Learning preferences-->
                    <div>
                        <h3 class="text-center sm:text-left pl-3 pb-2 pt-6">
                            {{ $t('ACCOUNT.LEARNING_PREFERENCES') }}
                        </h3>
                        <div class="md:flex md:flex-wrap" v-if="loaded">
                            <!--InterfaceLanguage-->
                            <select-field
                                class="w-full md:w-1/2"
                                :options="interfaceLanguageOptions"
                                v-model="account.interface_language_iso"
                                :is-valid="!errorHandler.has('interface_language_iso')"
                                :message="errorHandler.get('interface_language_iso')"
                                :preselect-first="true"
                                :label="$t('ACCOUNT.INTERFACE_LANGUAGE_TITLE')"/>

                            <!--LearningLanguage-->
                            <select-field
                                class="w-full md:w-1/2"
                                :options="learningLanguageOptions"
                                v-model="account.learning_language_iso"
                                :is-valid="!errorHandler.has('learning_language_iso')"
                                :message="errorHandler.get('learning_language_iso')"
                                :preselect-first="true"
                                :label="$t('ACCOUNT.LEARNING_LANGUAGE_TITLE')"/>

                            <!--Course info-->
                            <div class="w-full input">
                                <div class="input__label">{{ $t('ACCOUNT.CURRENT_COURSE') }}</div>
                                <p class="mb-0">{{ courseName }}</p>
                                <router-link
                                    class="link text-xs uppercase"
                                    :to="{name:'app.courses'}">
                                    {{ $t('CORE.CHANGE_COURSE') }}
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <!--Admin preferences-->
                    <div v-if="isAdmin">
                        <h3 class="text-center sm:text-left pl-3 pb-2 pt-6">
                            {{ $t('ACCOUNT.ADMIN_PREFERENCES_TITLE') }}
                        </h3>
                        <div class="md:flex md:flex-wrap">
                            <!--ForceContentFetchesFromApi-->
                            <checkbox
                                class="w-full"
                                v-model="onlyFetchFromApi"
                                :is-valid="true"
                                :label="$t('ACCOUNT.ADMIN_PREFERENCES_FORCE_API_USE')"/>
                        </div>
                    </div>

                    <!--Actions-->
                    <div class="md:flex md:flex-wrap md:justify-center pt-4">
                        <div class="w-full text-center pb-4">
                            <p class="text-green-500" v-text="successMsg"/>
                            <p class="text-red-500" v-text="errorMsg"/>
                        </div>
                        <div class="w-full md:w-1/2">
                            <btn
                                class="button__btn--primary button__btn--fullWidth"
                                :disabled="isFormProcessing"
                                v-text="$t('CORE.UPDATE')"
                                @click="handleUpdatingDetails"/>
                        </div>
                    </div>
                </form>
            </loading-capsule>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Btn from '@/components/Btn';
    import debounce from 'lodash/debounce';
    import VuexUtils from '@/mixins/VuexUtils';
    import Checkbox from '@/components/forms/fields/Checkbox';
    import InputField from '@/components/forms/fields/InputField';
    import SelectField from '@/components/forms/fields/SelectField';
    import ErrorResponseHandler from '@/helpers/ErrorResponseHandler';
    import LoadingCapsule from '@/components/structure/LoadingCapsule';

    export default {
        name      : 'Edit',
        mixins    : [VuexUtils],
        data      : function () {
            return {
                loaded          : false,
                formPopulated   : false,
                errorHandler    : new ErrorResponseHandler(),
                isFormProcessing: false,
                successMsg      : null,
                errorMsg        : null,
                account         : {
                    name                  : null,
                    email                 : null,
                    password              : null,
                    opt_in                : false,
                    learning_language_iso : null,
                    interface_language_iso: null,
                },
            };
        },
        created   : function () {
            this.$store.dispatch('Account/fetchUserFromApi', true)
                .then(() => {
                    this.populateForm();
                    this.loaded = true;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        methods   : {
            populateForm         : function () {
                this.account.name   = this.$store.getters['Account/getName'];
                this.account.email  = this.$store.getters['Account/getEmail'];
                this.account.opt_in = this.$store.getters['Account/getOptIn'];

                this.account.learning_language_iso  = this.$store.getters['Account/getLearningIso'];
                this.account.interface_language_iso = this.$store.getters['Account/getInterfaceIso'];
            },
            handleUpdatingDetails: debounce(function () {
                const payload = {
                    ...this.account,
                };

                this.$http
                    .patch(`api/users/${this.userUuid}`, payload)
                    .then(response => {
                        this.$store.dispatch('Account/setUserData', response.data.data)
                            .then(() => {
                                this.populateForm();
                                this.resetMainVuexContentStores();
                                this.isFormProcessing = false;
                                this.successMsg       = this.$t('ACCOUNT.SUBMIT_SUCCESS');
                            });
                    })
                    .catch(error => {
                        this.isFormProcessing = false;
                        this.errorMsg         = this.$t('ACCOUNT.SUBMIT_FAIL');
                        this.errorHandler.setResponse(error.response.data);
                        this.$scrollTo('#accountEditForm');
                    });
            }, 2000),
        },
        computed  : {
            ...mapGetters({
                interfaceLanguageOptions: 'Language/getInterfaceLanguages',
                learningLanguageOptions : 'Language/getLearningLanguages',
                isAdmin                 : 'Account/getUserIsAdmin',
                courseName              : 'Course/getCourseName',
            }),
            userUuid        : function () {
                return this.$store.getters['Account/getUuid'];
            },
            onlyFetchFromApi: {
                get: function () {
                    return this.$store.getters['Account/getOnlyFetchFromApi'];
                },
                set: function (value) {
                    this.$store.commit('Account/updateOnlyFetchFromApi', value);
                    this.isAdmin && this.resetAllVuexStores();
                },
            },
        },
        components: {
            InputField,
            SelectField,
            Checkbox,
            Btn,
            LoadingCapsule,
        },
    };
</script>
