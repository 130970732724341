<template>
    <div class="input">
        <label
            v-if="label"
            :for="`${_uid}-input`"
            class="input__label"
            v-text="label"/>
        <multiselect
            label="title"
            track-by="value"
            :preselectFirst="preselectFirst"
            :placeholder="placeholder"
            v-model="selected"
            :options="options">
            <!--The template for the selected if there are image icons for each option-->
            <template slot="singleLabel" slot-scope="props">
                <div class="flex">
                    <div class="w-8 h-4 flex items-center justify-start">
                        <img class="w-6 h-4"
                             :src="props.option.img">
                    </div>
                    <span class="ml-2 flex items-center justify-start">
                        <span class="option__title">{{ props.option.title }}</span>
                    </span>
                </div>
            </template>

            <!--The template for each option in dropdown if there are image icons for each option-->
            <template slot="option" slot-scope="props">
                <div class="flex">
                    <div class="w-1/12 flex items-center justify-start">
                        <img class="w-6"
                             :src="props.option.img">
                    </div>
                    <span class="ml-2 flex items-center justify-start">
                        <span class="option__title">{{ props.option.title }}</span>
                    </span>
                </div>
            </template>
        </multiselect>
        <p class="input__message"
           v-if="message"
           v-text="message"/>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';

    export default {
        name      : 'SelectField',
        data      : () => {
            return {
                selected: null,
            };
        },
        props     : {
            value         : {
                required: true,
            },
            label         : {
                default: null,
                type   : String,
            },
            options       : {
                required: true,
                type    : Array,
            },
            preselectFirst: {
                type   : Boolean,
                default: false,
            },
            placeholder   : {
                type   : String,
                default: 'Select an option',
            },
            message       : {
                required: false,
                type    : String,
                default : null,
            },
            isValid       : {
                default: null,
            },
        },
        methods   : {
            updateSelectedToValue: function () {
                this.selected = this.options.find(item => item.value === this.value);
            },
        },
        computed  : {
            inputHasNoValidStatus() {
                return this.isValid === null || this.selected === null;
            },
        },
        watch     : {
            selected(selectedItem) {
                if (selectedItem) {
                    this.$emit('input', selectedItem.value);
                }
            },
            value  : {
                immediate: true,
                handler() {
                    this.updateSelectedToValue();
                },
            },
            options: {
                handler() {
                    this.updateSelectedToValue();
                },
            },
        },
        components: {Multiselect},
    };
</script>
